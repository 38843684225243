<template>
  <div style="height: 100%;">
    <div class="radar-anal">
      <div style="font-size: 18px;color: #6A8ABE;font-weight: 500;text-align: left;">
        测评结果
      </div>
      <div class="radarFigureTable">
        <div class="figure">
          <div id="myChart" :style="{ width: '100%', height: '500px' }"></div>
        </div>

        <div class="table">
          <vueScroll :ops="ops">
            <Table :data="knowLedgeGrasp" :columns="columns"></Table>
          </vueScroll>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueScroll from 'vuescroll'
export default {
  name: 'radar',
  components: { vueScroll },
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {
          speed: 400
        },
        rail: {
          // size: '1px' // 滚动条大小
        },
        bar: {
          background: '#d2d2d2',
          opacity: 0.6, // 滚动条透明度
          onlyShowBarOnScroll: false, // 是否只在滚动时bar
          showDelay: 0 // 鼠标离开该区域多长时间隐藏
        }
      },
      columns: [
        {
          key: 'name',
          title: '知识点/能力名称'
        },
        {
          key: 'grasp',
          title: '掌握情况'
        }
      ],
      knowLedgeGrasp: [
        {
          name: '编程技术能力',
          grasp: '一般',
          max: 100
        },
        {
          name: '质量控制能力',
          grasp: '较好',
          max: 100
        },
        {
          name: '代码质量',
          grasp: '不错',
          max: 100
        },
        {
          name: '项目构建',
          grasp: '一般',
          max: 100
        },
        {
          name: '版本管理能力',
          grasp: '一般',
          max: 100
        },
        {
          name: '可视化',
          grasp: '较好',
          max: 100
        },
        {
          name: '页面设计能力',
          grasp: '不错',
          max: 100
        },
        {
          name: '通信',
          grasp: '一般',
          max: 100
        },
        {
          name: '语言基础',
          grasp: '较好',
          max: 100
        },
        {
          name: '性能优化',
          grasp: '不错',
          max: 100
        },
        {
          name: '服务端渲染',
          grasp: '一般',
          max: 100
        }
      ],
      option: {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          x: 'center',
          data: ['知识点能力']
        },
        radar: {
          indicator: [],
          center: ['35%', '50%'], // 调位置
          radius: '45%', // 调大小
          splitNumber: 5, // 圈数
          name: {
            textStyle: {
              color: '#888'
            }
          }
        },
        series: [
          {
            type: 'radar',
            symbol: 'circle', // 拐点样式
            itemStyle: { normal: { areaStyle: { type: 'default' } } },
            data: [
              {
                name: '知识点能力',
                itemStyle: {
                  normal: {
                    color: '#6A8ABE',
                    lineStyle: {
                      color: '#6A8ABE'
                    }
                  }
                },
                value: [75, 88, 95, 40, 60, 60, 95, 68, 78, 99, 100] // 动态数据
              }
            ]
          }
        ]
      },
      myEchart: null
    }
  },
  mounted() {
    this.initRadar()
  },
  methods: {
    getknowLedge(data) {},
    initRadar() {
      this.myEchart = this.$echarts.init(document.getElementById('myChart'))
      this.setOption()
    },
    setOption() {
      this.knowLedgeGrasp.map(data => {
        this.option.radar.indicator.push({
          name: data.name,
          max: data.max
        })
      })
      this.myEchart.setOption(this.option)
    }
  }
}
</script>

<style lang="less" scoped>
.radar-anal {
  height: 100%;
  padding: 0 30px;
  .radarFigureTable {
    height: 450px;
    .figure {
      height: 100%;
      float: left;
      width: 50%;
    }
    .table {
      height: 100%;
      float: left;
      width: 50%;
      overflow: auto;
    }
  }
}
</style>
