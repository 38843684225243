<template>
  <div class="reportContent">
    <div class="content-wrapper" style="margin-bottom: 30px;">
      <Breadcrumb class="custom-breadcrumb" ref="breadcrumb">
        <BreadcrumbItem :to="{ path: '/exam/list' }">考试管理</BreadcrumbItem>
        <BreadcrumbItem :to="{ path: pathDate.path, query: pathDate.routeQuery }">考试者详情</BreadcrumbItem>
        <BreadcrumbItem>{{ $route.query.name }}评估报告</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="report-title bg-shadow">
      <span>{{ $route.query.name }}成绩报告</span>
      <!--        <Button>下载报告</Button>-->
    </div>
    <div class="bg-shadow report-content" style="margin-top: 10px;">
      <div class="card-title">
        <p>{{ $route.query.name }}成绩报告单</p>
        <span>报告生成时间: 2019年11月15日10:42:15</span>
      </div>

      <div class="info">
        <div style="font-size: 18px;color: #6A8ABE;font-weight: 500;">
          基本信息
        </div>
        <div class="info-person">
          <div>姓名:{{ $route.query.name }}</div>
          <div>|</div>
          <div>手机:{{ $route.query.phone || '空' }}</div>
          <div>|</div>
          <div>邮箱:{{ $route.query.email || '空' }}</div>
        </div>
      </div>

      <div class="result">
        <div style="font-size: 18px;color: #6A8ABE;font-weight: 500;">
          测评结果
        </div>
        <div class="grade">
          <img :src="gradeImg" alt="" />
          <div style="width: calc(100% - 163px);padding: 20px 0 20px 15px;">
            <div style="display: flex;flex-direction: row;">
              <div class="grade-desc">得分{{ score }}</div>
              <!--                <div class="grade-desc" style="background:linear-gradient(90deg,rgba(181,66,169,1) 0%,rgba(214,101,118,1) 100%);">排名{{ranks}}</div>-->
              <!--                <div class="grade-desc" style="background:linear-gradient(90deg,rgba(54,181,165,1) 1%,rgba(115,195,123,1) 100%);">平均分{{avgScore}}</div>-->
            </div>
            <div class="evalu">
              <span>{{ rankDesc }}</span>
            </div>
          </div>
        </div>
        <div class="step">
          <div class="point" :style="StepLeft"></div>
        </div>
        <div class="title">
          <div v-for="r in rank" :key="r.name">{{ r.name }}</div>
        </div>
        <div class="exam-desc">
          <div class="exam-title" v-for="exam in examList" :key="exam.name">
            {{ exam.name }}
          </div>
          <div class="exam-card" v-for="(exam, index) in examList" :key="index">
            {{ exam.key }}
          </div>
        </div>
        <!--          <div class="question">-->
        <!--            <div class="question-title" v-for="question in questionTypeTitle" :key="question.name">{{ question.name }}</div>-->
        <!--            <div class="question-type" v-for="type in questionType" :key="type.name">-->
        <!--              <div>{{ type.name }}</div>-->
        <!--              <div>{{ type.count }}</div>-->
        <!--              <div>{{ Math.round(type.correctRate * 100) }}%</div>-->
        <!--            </div>-->
        <!--          </div>-->
      </div>

      <div style="margin-bottom: 25px;height: 500px;">
        <Radar ref="knowLedgeList"></Radar>
      </div>

      <div style="margin-bottom: 150px;">
        <Code ref="codeList"></Code>
      </div>
    </div>
  </div>
</template>

<script>
import Radar from './report/radar'
import Code from './report/code'
import examReport from '../../../../api/exam'
import unqualified from '../../../../assets/unqualified.png'
import qualified from '../../../../assets/qualified.png'
import good from '../../../../assets/good.png'
import excellent from '../../../../assets/excellent.png'
export default {
  name: 'reportPerCard',
  components: { Radar, Code, unqualified, qualified, good, excellent },
  data() {
    return {
      StepLeft: 'left: 0',
      totalCodeQuestion: 0,
      answerPaperId: this.$route.query.answerPaperId,
      paperId: this.$route.query.paperId,
      paperTotalScore: 150,
      totalScore: 0,
      score: 0,
      ranks: 1,
      avgScore: 85,
      gradeImg: null,
      rankDesc: '',
      evaluation: '良好',
      rank: [
        {
          name: '优秀'
        },
        {
          name: '良好'
        },
        {
          name: '合格'
        },
        {
          name: '不合格'
        }
      ],
      examList: [
        {
          name: '试卷名称',
          key: '暂无'
        },
        {
          name: '测试结果',
          key: '不通过'
        },
        {
          name: '测试分数',
          key: '暂无'
        },
        {
          name: '得分率（正确率）',
          key: '暂无'
        },
        {
          name: '成绩排名',
          key: '暂无'
        },
        {
          name: '测试用时',
          key: '暂无'
        }
      ],
      questionTypeTitle: [
        {
          name: '题型'
        },
        {
          name: '题目数量'
        },
        {
          name: '得分率(正确率)'
        }
      ],
      questionType: [],
      CodeVoList: null,
      path: '',
      pathDate: {
        path: '',
        routeQuery: {}
      }
    }
  },
  mounted() {
    this.getPersonalReport()
    this.getCodeAnalDesc()
    this.pathDate = {
      // 配置路由
      path: '/exam/examinee',
      routeQuery: {
        examId: this.$route.query.examId,
        paperId: this.$route.query.paperId
      }
    }
  },
  methods: {
    getCodeAnalDesc() {
      examReport.getCodeAnal(this.answerPaperId, this.paperId).then(res => {
        this.CodeVoList = res.res
        this.totalCodeQuestion = this.CodeVoList.totalCodeQuestion
        if (this.totalCodeQuestion > 0) {
          this.$refs.codeList.getCodeAnalList(this.CodeVoList)
        }
      })
    },
    getPersonalReport() {
      // this.answerPaperId = 889
      // this.paperId = 367
      examReport.getExamPersonalReport(this.answerPaperId, this.paperId).then(res => {
        let paperReportExam = res.res
        let getScoreRank = paperReportExam.basicExamInfoVO.centesimalScore
        this.rankDesc = paperReportExam.evaluate // 评价
        this.$refs.knowLedgeList.getknowLedge(
          paperReportExam.capabilityRadarDiagram // 雷达图知识点
        )
        this.examList[3].key = Math.round(getScoreRank * 100) + '%'
        this.examList[2].key = paperReportExam.basicExamInfoVO.score.substring(0, paperReportExam.basicExamInfoVO.score.indexOf('/'))
        this.examList[5].key = this.getTimeInterval(paperReportExam.basicExamInfoVO.duration)
        this.getScoreOrTotalScore(paperReportExam)
        this.getScoreDesc(getScoreRank)
        res.res.personalQuestionInfoVOS.map((data, index) => {
          this.questionType[index] = {
            name: data.sectionName,
            count: data.totalCount,
            correctRate: data.getScoreRate
          }
        })
        // res.res.personalEvaluationKnowledgePointVOS.map((data, index) => {
        //   this.knowLedgeGrasp[index] = {
        //     name: data.knowledgePointName
        //   }
        // })
      })
    },
    getTimeInterval(ts) {
      var timeLeft = [0, 0, 0, 0],
        timeStr = ''
      timeLeft[0] = ts > 86400 ? parseInt(ts / 86400) : 0
      ts = ts - timeLeft[0] * 86400
      timeLeft[1] = ts > 3600 ? parseInt(ts / 3600) : 0
      ts = ts - timeLeft[1] * 3600
      timeLeft[2] = ts > 60 ? parseInt(ts / 60) : 0
      timeLeft[3] = ts - timeLeft[2] * 60
      timeStr = timeLeft[0] > 0 ? timeLeft[0] + '天' : ''
      timeStr += timeLeft[0] <= 0 && timeLeft[1] <= 0 ? '' : timeLeft[1] + '小时'
      timeStr += timeLeft[0] <= 0 && timeLeft[1] <= 0 && timeLeft[2] <= 0 ? '' : timeLeft[2] + '分钟'
      timeStr += timeLeft[0] <= 0 && timeLeft[1] <= 0 && timeLeft[2] <= 0 && timeLeft[3] <= 0 ? '' : timeLeft[3] + '秒'
      return timeStr
    },
    getScoreOrTotalScore(paperReportExam) {
      this.totalScore = paperReportExam.basicExamInfoVO.score.substring(
        paperReportExam.basicExamInfoVO.score.lastIndexOf('/') + 1,
        paperReportExam.basicExamInfoVO.score.length
      )
      this.score = paperReportExam.basicExamInfoVO.score.substring(0, paperReportExam.basicExamInfoVO.score.lastIndexOf('/'))
      this.examList[0].key = paperReportExam.basicExamInfoVO.examName
    },
    getScoreDesc(getScoreRank) {
      if (getScoreRank >= 0.6) {
        this.examList[1].key = '通过'
      }
      if (getScoreRank < 0.6) {
        this.gradeImg = unqualified
        this.evaluation = '不合格'
        this.StepLeft = 'left: 87%'
        this.examList[1].key = '不合格'
      } else if (getScoreRank >= 0.6 && getScoreRank < 0.7) {
        this.gradeImg = qualified
        this.evaluation = '合格'
        this.StepLeft = 'left: 62%'
        this.examList[1].key = '合格'
      } else if (getScoreRank >= 0.7 && getScoreRank < 0.85) {
        this.gradeImg = good
        this.evaluation = '良好'
        this.StepLeft = 'left: 37%'
        this.examList[1].key = '良好'
      } else {
        this.gradeImg = excellent
        this.evaluation = '优秀'
        this.StepLeft = 'left: 12%'
        this.examList[1].key = '优秀'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.reportContent {
  width: 100%;
  height: 100%;
  padding: 10px 200px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  .report-title {
    height: 55px;
    padding: 30px 0 30px 62px;
    text-align: left;
    display: flex;
    align-items: center;
    /*padding-left: 62px;*/
  }
  .report-content {
    .card-title {
      text-align: center;
      margin-top: 35px;
      p {
        color: #333;
        font-size: 30px;
        font-weight: 500;
      }
      span {
        color: #666;
        font-size: 14px;
        font-weight: 300;
      }
    }
    .info {
      padding: 15px 30px;
      text-align: left;
      .info-person {
        width: 100%;
        height: 45px;
        margin-top: 30px;
        background: #f7f7f7;
        border-radius: 3px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 25px;
        div {
          padding-right: 30px;
          font-size: 16px;
          font-weight: 300;
          color: #666;
        }
      }
    }
    .result {
      padding: 15px 30px;
      text-align: left;
      .grade {
        display: flex;
        flex-direction: row;
        img {
          padding: 20px;
          width: 123px;
          height: 151px;
        }
        .grade-desc {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 166.2px;
          height: 45px;
          margin-right: 15px;
          border-radius: 12px;
          /*text-align: center;*/
          color: #fff;
          font-size: 24px;
          background: linear-gradient(90deg, rgba(53, 111, 187, 1) 0%, rgba(44, 201, 214, 1) 100%);
        }
        .evalu {
          margin-top: 10px;
          height: 70px;
          padding: 8px 15px;
          background: #f7f7f7;
          border-radius: 3px;
          white-space: pre-wrap;
          word-break: break-all;
        }
      }
      .step {
        position: relative;
        padding-right: 15px;
        width: 100%;
        height: 10px;
        margin-top: 15px;
        border-radius: 9px;
        background: linear-gradient(90deg, rgba(198, 54, 66, 1) 0%, rgba(219, 133, 76, 1) 42%, rgba(57, 182, 163, 1) 100%);
        .point {
          position: absolute;
          background-color: #fff;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          bottom: 0;
          top: -10px;
          border: 1px solid #6a8abe;
        }
      }
      .title {
        width: 100%;
        height: 20px;
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        div {
          color: #333;
          font-weight: bold;
          font-size: 16px;
          width: 25%;
          text-align: center;
        }
      }
      .exam-desc {
        height: 110px;
        div {
          display: flex;
          align-items: center;
          border: 1px solid #e5e5e5;
          padding-left: 25px;
          width: 16.6%;
          float: left;
          height: 46px;
          font-size: 14px;
          font-weight: 300;
          color: #666;
        }
        .exam-title {
          margin-top: 15px;
          background: #f7f7f7;
        }
        .exam-card {
        }
      }
      .question {
        margin: 20px 0;
        height: 190px;
        overflow-y: auto;
        div {
          display: flex;
          align-items: center;
          border: 1px solid #e5e5e5;
          height: 46px;
          font-size: 14px;
          font-weight: 300;
          color: #666;
        }
        .question-title {
          background: #f7f7f7;
          padding-left: 20px;
          float: left;
          width: 33.3%;
        }
        .question-type {
          display: contents;
          height: 150px;
          overflow: auto;
          div {
            width: 33.3%;
            float: left;
            padding-left: 20px;
          }
        }
      }
    }
  }
}
</style>
