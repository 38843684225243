<template>
  <div class="code">
    <div class="codeAnal">编程能力分析</div>
    <div class="codeResult">
      <div class="result1 styleCenter">
        <p>
          通过: <span>{{ passCodeQuestion }}道</span>(共{{ totalCodeQuestion }}道)
        </p>
        <p>代码编译结果</p>
      </div>
      <div class="result2 styleCenter">
        <p>
          通过率: <span>{{ totalPassRate || 0 }}</span>
        </p>
        <p>通过的测试用例</p>
      </div>
    </div>
    <div v-if="totalCodeQuestion > 0" style="height: 320px;">
      <vueScroll :ops="ops">
        <div v-for="(code, index) in codeList" :key="index" style="text-align: left;margin: 20px 0;height: 120px;">
          <div class="codeIndex">第{{ index + 1 }}道题的代码统计</div>
          <div class="cssStyle" v-for="title in titleList" :key="title.name">
            <div class="centerHoriz" style="width: 100%;background: #f7f7f7;height: 100%;padding: 0 15px;">
              {{ title.name }}
            </div>
          </div>
          <div class="cssStyle centerHoriz">{{ code.language || 'java' }}</div>
          <div class="cssStyle centerHoriz">{{ code.compileTimes }}</div>
          <div class="cssStyle centerHoriz">{{ code.compileSuccess }}</div>
          <div class="cssStyle centerHoriz">{{ code.compileError }}</div>
          <div class="cssStyle centerHoriz">{{ code.runError }}</div>
          <div class="cssStyle centerHoriz">
            {{ code.everyCompileCase === '0.0%' ? 0 : code.everyCompileCase }}
          </div>
        </div>
      </vueScroll>
    </div>
  </div>
</template>

<script>
import vueScroll from 'vuescroll'
export default {
  name: 'code',
  components: { vueScroll },
  data() {
    return {
      totalCodeQuestion: 0,
      totalPassRate: 0,
      passCodeQuestion: 0,
      ops: {
        vuescroll: {},
        scrollPanel: {
          speed: 400
        },
        rail: {
          // size: '1px' // 滚动条大小
        },
        bar: {
          background: '#d2d2d2',
          opacity: 0.6, // 滚动条透明度
          onlyShowBarOnScroll: false, // 是否只在滚动时bar
          showDelay: 0 // 鼠标离开该区域多长时间隐藏
        }
      },
      titleList: [
        {
          name: '使用语言'
        },
        {
          name: '尝试运行编译总数'
        },
        {
          name: '编译次数（编译成功）'
        },
        {
          name: '编译异常次数'
        },
        {
          name: '运行错误的编译次数'
        },
        {
          name: '每次编译平均通过用例'
        }
      ],
      codeList: [],
      CodeVoList: null
    }
  },
  methods: {
    getCodeAnalList(data) {
      this.totalCodeQuestion = data.totalCodeQuestion // 编程题总数
      this.totalPassRate = data.totalPassRate === '0.0%' ? 0 : data.totalPassRate // 测试用例通过率
      this.passCodeQuestion = data.passCodeQuestion // 通过编程题数
      let ExamCodeVOList = data.evaluateExamCodeVOList
      if (ExamCodeVOList.length !== 0) {
        for (let i = 0; i < ExamCodeVOList.length; i++) {
          this.codeList.push({
            questionId: ExamCodeVOList[i].questionId, // 题目Id
            language: ExamCodeVOList[i].language, // 语言
            compileTimes: ExamCodeVOList[i].tryTimes, // 编译总次数
            compileSuccess: ExamCodeVOList[i].successTimes, // 编译成功次数
            everyCompileCase: ExamCodeVOList[i].avgPassRate, // 平均通过率
            compileError: ExamCodeVOList[i].exceptionTimes, // 编译异常次数
            runError: ExamCodeVOList[i].failureTimes // 编译失败次数
          })
        }
      }
      // else {
      //   this.codeList.push({
      //     questionId: 0,
      //     language: '',
      //     compileTimes: 0,
      //     compileSuccess: 0,
      //     everyCompileCase: 0,
      //     compileError: 0,
      //     runError: 0
      //   })
      // }
    }
  }
}
</script>

<style lang="less" scoped>
.centerHoriz {
  display: flex;
  align-items: center;
  padding: 0 15px;
}
.cssStyle {
  height: 45px;
  width: 16.66%;
  float: left;
  background: #fff;
  border: 1px solid #e5e5e5;
  /*padding: 0 10px;*/
}
.styleCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.code {
  padding: 0 30px;
  .codeAnal {
    font-size: 18px;
    color: #6a8abe;
    font-weight: 500;
    text-align: left;
  }
  .codeResult {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    div {
      width: 245px;
      height: 75px;
      border-radius: 12px;
      margin-right: 20px;
      color: #fff;
    }
    .result1 {
      background: linear-gradient(90deg, rgba(53, 111, 187, 1) 0%, rgba(58, 182, 162, 1) 100%);
    }
    .result2 {
      background: linear-gradient(90deg, rgba(183, 155, 107, 1) 0%, rgba(214, 101, 118, 1) 100%);
    }
    p {
      font-size: 16px;
      font-weight: 400;
    }
    span {
      font-size: 25px;
      font-weight: bold;
    }
  }
  .codeIndex {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 10px;
  }
}
</style>
